import React from 'react'
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
        <div className="header-nav">
            <div className="header-container">
            <div className="logo">
                  <img src="images/img_epriselogo.png" alt="eprise-logo" />
              </div>
                  <div className="header-section">
                  <a href="https://eprise.biz/web/index.html#/login" target="_blank" rel="noopener noreferrer">
                      <button className="btn header-btn">Login</button>
                  </a>
                  <Link to="/demo">
                      <button className="btn header-btn">Request a Demo</button>
                  </Link>
              </div>
            </div>
        </div>
    </>
  )
}

export default Header

