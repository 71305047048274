import React, { useEffect } from "react";
import Header from "../components/Header";
//import Footer from "../components/Footer";
import MainFooter from "../components/MainFooter";
import "../core-ui/Terms.css";
import { Link } from "react-router-dom";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="t-c-container">
        <div></div>
        <div className="t-c-content">
          <h1>Terms and Conditions</h1>
          <div className="horizontal-line"></div>
          <p>Last updated on: 01st September 2024</p>
          <p>
            This is an agreement between you or the entity that you represent
            (hereinafter “you” or “your” or “user”) and Qliclabs Technologies
            Private Limited (hereinafter “Qliclabs”), a company incorporated
            under the Companies Act, 2013 having CIN U72900KA2019PTC121576 with
            its office at Flat No.502, 5th Floor, "Sri Devidayal Canopus",
            Kodical Main Road, Ashoknagar PO, Derebail Village, Mangaluru-575006
            and Development centre at K Tech Innovation Hub IKP, 3rd Floor,
            Plama Center, Bejai - Kapikad Road, Mangaluru -575004.{" "}
          </p>
          <p>
            Qliclabs governs the use of the Mobile Application, Desktop
            Application, Web based application, Store, Website, platform and any
            other services offered (hereinafter “Eprise”),{" "}
          </p>
          <p>
            It is clarified that in case of a company, partnership, trust or any
            other legal entity which uses or accesses the Services or the
            Qliclabs Products, "User" shall include such company, partnership,
            trust or any other legal entity.{" "}
          </p>
          <h2>Parts of this Agreement </h2>
          <p>
            This Agreement consists of General Terms and Service Specific Terms.
            By procuring a service from Qliclabs, or by use of Eprise, the User
            agrees to be bound by these General Terms and Service Specific
            Terms. In the event of a conflict between the General Terms and
            Service Specific Terms, the Service Specific Terms shall prevail.{" "}
          </p>
          <h2>Acceptance of the Agreement </h2>
          <p>
            You must be of legal age to enter into a binding agreement in order
            to accept the Agreement. If you do not agree to the General Terms,
            do not use any of our Services. If you agree to the General Terms
            and do not agree to any Service Specific Terms, do not use the
            corresponding Service. You can accept the Agreement by checking a
            checkbox or clicking on a button indicating your acceptance of the
            Agreement or by actually using the Services.
          </p>
          <p>
            Please note that by use of the Eprise and any services provided by
            Qliclabs, the User also agree to be bound by Qliclabs' Privacy
            Policy, Cancellation and Refund Policy, and other policies of
            Qliclabs and the rules applicable to each Qliclabs Products or
            Services.{" "}
          </p>
          <h2>Definition</h2>
          <p>
            Unless the context otherwise requires, the following capitalized
            words shall have the meaning assigned to them below.
          </p>
          <p>
            <span>“Applicable Laws”</span> means all applicable{" "}
          </p>
          <ol type="1">
            <li>
              statutes, enactments, acts of legislature or parliament, laws,
              bye-laws, ordinances, rules, regulations, listing agreements,
              notifications, guidelines or policies of any applicable
              jurisdiction;{" "}
            </li>
            <li>
              administrative interpretation, writ, injunction, directions,
              directives, judgment, arbitral award, decree, orders or consents
              of, or agreements with, any Governmental Authority or a recognized
              stock exchange; and{" "}
            </li>
            <li>
              international tax treaties, as may be in force from time to time{" "}
            </li>
          </ol>
          <p>
            <span>“Desktop Application”</span> means the computer application
            developed by Qliclabs for availing the Services;
          </p>
          <p>
            <span>“Eprise”</span> means collectively, the Mobile Application,
            Desktop Application, Store, Website, Platform and any other products
            provided by Qlilcabs to the Users from time to time;{" "}
          </p>
          <p>
            <span>“Fees” </span> means the fee payable for availing of the
            Services or product, as specified by Qlilcabs;{" "}
          </p>
          <p>
            <span>“General Terms” </span> means terms and conditions included in
            this agreement but does not include Service Specific Terms;{" "}
          </p>
          <p>
            <span>“Governmental Authority” </span> means any Indian or
            non-Indian court of competence, arbitral tribunal, legislative,
            executive or administrative agency or commission or other
            governmental or regulatory agency or authority or any securities
            exchange;{" "}
          </p>
          <p>
            <span>“Mobile Application”</span>means the mobile application
            developed by Qlilcabs for availing the Services;{" "}
          </p>
          <p>
            <span>“Services”</span>means the invoicing, business and accounting
            management, online store services provided by Qliclabs to an User
            through the Mobile Application, Desktop Application, Website, Store
            and any other myBillBook Products from time to time;{" "}
          </p>
          <p>
            <span>“Service Specific Terms”</span> means terms and conditions, if
            any, specific to use of individual Services;{" "}
          </p>
          <p>
            <span>“Website”</span> means the website or web links provided by
            Qliclabs for availing the Services or Product.{" "}
          </p>
          <h2>Description of Service </h2>
          <p>
            Qliclabs offers the Eprise and any other services conditioned upon
            the User’s acceptance of all terms, conditions, policies, and
            notices stated here. By procuring a service from Qliclabs, or by use
            of Eprise, the User agrees to be bound by General Terms and Service
            Specific terms. You may use the Services or Product for your
            personal and business use or for internal business purpose in the
            organization that you represent. You may connect to the Services
            using any Internet browser supported by the Services. You are
            responsible for obtaining access to the Internet and the equipment
            necessary to use the Services. You can create and edit content with
            your user account and if you choose to do so, you can publish and
            share such content.{" "}
          </p>
          <p>
            Any new product or services provided by Qliclabs, or any new
            features or tools which are added to the Mobile Application, Desktop
            Application, Website, Store, platform, shall, unless specifically
            stated otherwise, be subject to these Terms of Service.{" "}
          </p>
          <h2>Beta Service </h2>
          <p>
            We may offer certain Services or Product as closed or open beta
            services or Beta product for the purpose of testing and evaluation.
            You agree that we have the sole authority and discretion to
            determine the period of time for testing and evaluation of Beta
            Services or Beta Product . We will be the sole judge of the success
            of such testing and the decision, if any, to offer the Beta Services
            or Beta Product as commercial services or Product. You will be under
            no obligation to acquire a subscription to use any paid Service as a
            result of your subscription to any Beta Service or Beta Product. We
            reserve the right to fully or partially discontinue, at any time and
            from time to time, temporarily or permanently, any of the Beta
            Services or Beta products with or without notice to you. You agree
            that Qliclabs will not be liable to you or to any third party for
            any harm related to, arising out of, or caused by the modification,
            suspension or discontinuance of any of the Beta Services or Beta
            product for any reason.
          </p>
          <h2>Free Trail </h2>
          <p>
            If you register for a free trial of one or more Services, Qliclabs
            will make the applicable Services available to you on a trial basis
            free of charge until the earlier of{" "}
          </p>
          <ol type="1">
            <li>
              the end of the free trial period of the applicable Services
              (unless terminated earlier by you),{" "}
            </li>
            <li>
              the start date of the paid subscription period for the applicable
              Services, or{" "}
            </li>
            <li>termination by Qliclabs in its sole discretion. </li>
          </ol>
          <p>
            Any data that you enter into the Services, and any customizations
            made to the Services during the free trial will be permanently lost
            unless you{" "}
          </p>
          <ol type="1">
            <li>
              purchase the corresponding paid subscription plan for the account,{" "}
            </li>
            <li>purchase applicable Service upgrades, or</li>
            <li>export such data before the end of the trial period. </li>
          </ol>
          <p>
            Notwithstanding anything contained in this Section, Services are
            offered as-is during the free trial, without any warranty, covenant,
            support or liability whatsoever, to the extent permitted by law.{" "}
          </p>
          <h2>User Sign up Obligations. </h2>
          <p>
            You need to sign up for a user account by providing all required
            information in order to access or use the Services. If you represent
            an organization and wish to use the Services for corporate internal
            use, we recommend that you, and all other users from your
            organization, sign up for user accounts by providing your corporate
            contact information. In particular, we recommend that you use your
            corporate email address.{" "}
          </p>
          <p>You agree to: </p>
          <ol type="1">
            <li>
              provide true, accurate, current and complete information about
              yourself as prompted by the sign up process; and{" "}
            </li>
            <li>
              maintain and promptly update the information provided during sign
              up to keep it true, accurate, current, and complete.{" "}
            </li>
          </ol>
          <p>
            If you provide any information that is untrue, inaccurate, outdated,
            or incomplete, or if Qliclabs has reasonable grounds to suspect that
            such information is untrue, inaccurate, outdated, or incomplete,
            Qliclabs may terminate your user account and refuse current or
            future use of any or all of the Services.{" "}
          </p>
          <h2>Restrictions on Use </h2>
          <p>
            In addition to all other terms and conditions of this Agreement, you
            shall not:{" "}
          </p>
          <ol type="1">
            <li>
              transfer the Services or otherwise make it available to any third
              party;
            </li>
            <li>
              provide any service based on the Services without prior written
              permission;{" "}
            </li>
            <li>
              allow user licenses to be shared or used by more than one
              individual other than by way of reassigning the user license to a
              new user;{" "}
            </li>
            <li>
              except as permitted under applicable law, attempt to disassemble,
              reverse engineer or decompile the Services;{" "}
            </li>
            <li>
              use the third party links to sites without agreeing to their
              website terms & conditions;{" "}
            </li>
            <li>
              post links to third party sites or use their logo, company name,
              etc. without their prior written permission;{" "}
            </li>
            <li>
              attempt to gain unauthorized access to the Services or its related
              systems or network;{" "}
            </li>
            <li>
              use the Services in any manner that could damage, disable,
              overburden, impair or harm any server, network, computer system,
              resource of Qliclabs;{" "}
            </li>
            <li>
              use the Services to send or store material containing software
              viruses, worms or other harmful computer codes, files, scripts or
              programs;{" "}
            </li>
            <li>
              use the Services in any manner that interferes with or disrupts
              the integrity, security or performance of the Services, its
              components and the data contained therein;{" "}
            </li>
            <li>
              host, display, upload, modify, publish, transmit, store, update or
              share any information that belongs to another person or entity and
              to which you do not have any right, including personal or
              confidential information of any person or entity with respect to
              which you do not have consent or permission from such person or
              entity;
            </li>
            <li>
              violate any applicable local, state, national or international
              law;{" "}
            </li>
            <li>
              use the Services for any form of competitive or benchmarking
              purposes; and{" "}
            </li>
            <li>
              remove or obscure any proprietary or other notices contained in
              the Services;{" "}
            </li>
            <li>
              use our Services in any manner that threatens the unity,
              integrity, defence, security or sovereignty of India, friendly
              relations of India with other countries, or public order, or
              causes incitement to the commission of any cognisable offence or
              prevents investigation of any offence or is insulting other
              countries;{" "}
            </li>
            <li>
              create a false identity to mislead any person as to the identity
              or origin of any communication;{" "}
            </li>
            <li>
              use the services for transmitting information that is patently
              false and untrue, and is written or published in any form, with
              the intent to mislead or harass a person, entity or agency for
              financial gain or to cause any injury to any person; or{" "}
            </li>
            <li>
              use the services in a manner that relates to or encourages any
              activity prohibited by law in India.{" "}
            </li>
          </ol>
          <h2>Product Upgrade Limitations </h2>
          <p>
            During the subscription period, user will be notified when and if
            new Qliclabs software version upgrades become available. Users will
            NOT be entitled to receive a version upgrade generally commercially
            released after the expiration date of a subscription. Version
            upgrades are provided for use by users only and are strictly
            prohibited from resale.{" "}
          </p>
          <h2>Spamming and Illegal Activities </h2>
          <p>
            You agree to be solely responsible for the contents of your
            transmissions through the Services. You agree not to use the
            Services for illegal purposes or for the transmission of material
            that is unlawful, defamatory, insulting, harassing, libelous,
            invasive of another's privacy (including bodily privacy), abusive,
            threatening, harmful, vulgar, pornographic, paedophilic, harmful to
            children, obscene, racially or ethnically objectionable, or is
            otherwise objectionable, offends religious sentiments, promotes
            racism, contains viruses or malicious code, or that which infringes
            or may infringe intellectual property or other rights of another.
            You agree not to use the Services for the transmission of "junk
            mail", "spam", "chain letters", “phishing” or unsolicited mass
            distribution of email. We reserve the right to terminate your access
            to the Services if there are reasonable grounds to believe that you
            have used the Services for any illegal or unauthorized activity.{" "}
          </p>
          <h2>Third Party Applications </h2>
          <p>
            Qliclabs Services integrate with many third party applications
            (hereinafter "Third Party Application(s)"). Access and use of the
            Third Party Applications may require acceptance of terms of service
            and privacy policies applicable to such Third Party Applications
            (hereinafter "Third Party Terms"). You are responsible for reading
            and understanding the Third Party Terms before accessing or using
            any Third Party Application. You acknowledge and agree that Qliclabs
            is not liable for any Third Party Applications. While we will try to
            provide you with advance notice, whenever reasonably possible, you
            acknowledge and agree that Qliclabs may, at any time and in our sole
            discretion, and without any notice to you, suspend, restrict or
            disable access to or remove from Qliclabs Services, any Third Party
            Application, without any liability to you, including without
            limitation for any loss of profits, revenue, data, goodwill or other
            intangible losses.{" "}
          </p>
          <h2>Fees and Payments </h2>
          <p>
            The Services are available under subscription plans. If you do not
            wish to renew the subscription, you must inform us at least seven
            days prior to the renewal date. If you have not downgraded to a free
            plan and if you have not informed us that you do not wish to renew
            the subscription, you will be presumed to have authorized Qliclabs
            to charge the subscription fee to the Credit Card last used by you.
            Please click here to know about our{" "}
            <Link to="/refund-and-Cancellation">
              <span>Refund Policy</span>
            </Link>
            .
          </p>
          <p>
            From time to time, we may change the price of any Service or charge
            for use of Services that are currently available free of charge. Any
            increase in charges will not apply until the expiry of your then
            current billing cycle. You will not be charged for using any Service
            unless you have opted for a paid subscription plan.{" "}
          </p>
          <p>
            In the event any tax such as GST, VAT, sales tax or the like is
            chargeable by Qliclabs in accordance with any applicable laws with
            respect to your subscription to our Services ("Taxes"), Qliclabs
            will invoice you for such Taxes. You agree to pay Qliclabs such
            Taxes in addition to the subscription fees. Qliclabs shall provide
            you with an invoice in the format prescribed by the applicable laws
            to help you avail the applicable input tax credit for the Taxes so
            paid.{" "}
          </p>
          <h2>Organization Accounts and Administrators </h2>
          <p>
            When you sign up for an account for your organization you may
            specify one or more administrators. The administrators will have the
            right to configure the Services based on your requirements and
            manage end users in your organization account. If your organization
            account is created and configured on your behalf by a third party,
            it is likely that such third party has assumed administrator role
            for your organization. Make sure that you enter into a suitable
            agreement with such third party specifying such party's roles and
            restrictions as an administrator of your organization account.{" "}
          </p>
          <p>You are responsible for </p>
          <ol type="1">
            <li>
              ensuring confidentiality of your organization account password,{" "}
            </li>
            <li>
              appointing competent individuals as administrators for managing
              your organization account, and{" "}
            </li>
            <li>
              ensuring that all activities that occur in connection with your
              organization account comply with this Agreement. You understand
              that Qliclabs is not responsible for account administration and
              internal management of the Services for you.{" "}
            </li>
          </ol>
          <p>
            You are responsible for taking necessary steps for ensuring that
            your organization does not lose control of the administrator
            accounts. You may specify a process to be followed for recovering
            control in the event of such loss of control of the administrator
            accounts by sending an email to support@qliclabs.com, provided that
            the process is acceptable to Qliclabs. In the absence of any
            specified administrator account recovery process, Qliclabs may
            provide control of an administrator account to an individual
            providing proof satisfactory to Qliclabs demonstrating authorization
            to act on behalf of the organization. You agree not to hold Qliclabs
            liable for the consequences of any action taken by Qliclabs in good
            faith in this regard.{" "}
          </p>
          <h2>Personal Information and Privacy </h2>
          <p>
            Personal information you provide to Qliclabs through the Service is
            governed by Qliclabs Privacy Policy. Your election to use the
            Service indicates your acceptance of the terms of the Qliclabs
            Privacy Policy. You are responsible for maintaining confidentiality
            of your username, password and other sensitive information. You are
            responsible for all activities that occur in your user account and
            you agree to inform us immediately of any unauthorized use of your
            user account by email to support@qliclabs.com or by calling us on
            any of the numbers listed on{" "}
            <a
              href="https://www.qliclabs.com/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>https://www.qliclabs.com/ </span>
            </a>
            We are not responsible for any loss or damage to you or to any third
            party incurred as a result of any unauthorized access and/or use of
            your user account, or otherwise
          </p>
          <h2>Communications from Qliclabs </h2>
          <p>
            The Service may include certain communications from Qliclabs, such
            as service announcements, administrative messages and newsletters.
            You understand that these communications shall be considered part of
            using the Services. As part of our policy to provide you total
            privacy, we also provide you the option of opting out from receiving
            newsletters from us. However, you will not be able to opt-out from
            receiving service announcements and administrative messages.{" "}
          </p>
          <h2>Complaints </h2>
          <p>
            If we receive a complaint from any person with respect to your
            activities as part of use of the Services (other than where the
            grievance redressal mechanism provided under the head "Grievance
            Redressal" applies), we will forward the complaint to the primary
            email address of your user account. You must respond to the
            complainant directly within 10 days of receiving the complaint
            forwarded by us and copy Qliclabs in the communication. If you do
            not respond to the complainant within 10 days from the date of our
            email to you, we may disclose your name and contact information to
            the complainant for enabling the complainant to take legal action
            against you. You understand that your failure to respond to the
            forwarded complaint within the 10 days' time limit will be construed
            as your consent to disclosure of your name and contact information
            by Qliclabs to the complainant.{" "}
          </p>
          <h2>Grievance Redressal </h2>
          <p>
            In case of any grievance, the term as defined under Information
            Technology (Intermediary Guidelines and Digital Media Ethics Code)
            Rules 2021, you can contact Qliclabs by sending an email to -{" "}
            <a href="mailto:support@qliclabs.com">
              <span>support@qliclabs.com</span>
            </a>
            or by phone at{" "}
            <a href="tel:+918904052211">
              <span>+91 8904052211</span>
            </a>{" "}
            or{" "}
          </p>
          <h2>Inactive User Accounts Policy </h2>
          <p>
            We reserve the right to terminate unpaid user accounts. In the event
            of such termination, all data associated with such user account will
            be deleted. We will provide you prior notice of such termination and
            option to back-up your data. The data deletion policy may be
            implemented with respect to any or all of the Services. Each Service
            will be considered an independent and separate service for the
            purpose of calculating the period of inactivity. In other words,
            activity in one of the Services is not sufficient to keep your user
            account in another Service active. In case of accounts with more
            than one user, if at least one of the users is active, the account
            will not be considered inactive.{" "}
          </p>
          <h2>Hosting Location </h2>
          <p>
            The location of the cloud facility from which you are served depends
            on the mapping of your region/country to the available cloud
            facilities at the time of your sign-up. We may migrate your account
            or require you to migrate your account to a different cloud facility
            in the event of any updates to the region/country to cloud facility
            mapping at any point of time. You must not mask your internet
            protocol (IP) address at the time of sign-up since your
            region/country is determined base on your IP address. If, at any
            time, your actual region/country is found to be different from the
            region/country in our records, Qliclabs may take appropriate action
            such as migrate your account or require you to migrate your account
            to the cloud facility corresponding to your region/country, or close
            your account and deny the Service to you. If you are served from a
            cloud facility outside your region/country and a Qliclabs group
            entity has an office in your region/country, apart from storing the
            data in the cloud facility assigned to you, we may store a local
            copy of the data in your region/country.{" "}
          </p>
          <h2>Data Ownership </h2>
          <p>
            We respect your right to ownership of content created or stored by
            you. You own the content created or stored by you. Unless
            specifically permitted by you, your use of the Services does not
            grant Qliclabs the license to use, reproduce, adapt, modify, publish
            or distribute the content created by you or stored in your user
            account for Qliclabs' commercial, marketing or any similar purpose.
            But you grant Qliclabs permission to access, copy, distribute,
            store, transmit, reformat, publicly display and publicly perform the
            content of your user account solely as required for the purpose of
            providing the Services to you.{" "}
          </p>
          <h2>User Generated Content </h2>
          <p>
            You may transmit or publish content created by you using any of the
            Services or otherwise. However, you shall be solely responsible for
            such content and the consequences of its transmission or
            publication. If you are a publisher of news and current affairs
            content as defined under Information Technology (Intermediary
            Guidelines and Digital Media Ethics Code) Rules 2021, you shall
            furnish details to the Ministry of Information and Broadcasting as
            required by rule 18. Any content made public will be publicly
            accessible through the internet and may be crawled and indexed by
            search engines. You are responsible for ensuring that you do not
            accidentally make any private content publicly available. Any
            content that you may receive from other users of the Services, is
            provided to you AS IS for your information and personal use only and
            you agree not to use, copy, reproduce, distribute, transmit,
            broadcast, display, sell, license or otherwise exploit such content
            for any purpose, without the express written consent of the person
            who owns the rights to such content. In the course of using any of
            the Services, if you come across any content with copyright
            notice(s) or any copy protection feature(s), you agree not to remove
            such copyright notice(s) or disable such copy protection feature(s)
            as the case may be. By making any copyrighted/copyrightable content
            available on any of the Services you affirm that you have the
            consent, authorization or permission, as the case may be from every
            person who may claim any rights in such content to make such content
            available in such manner. Further, by making any content available
            in the manner aforementioned, you expressly agree that Qliclabs will
            have the right to block access to or remove such content made
            available by you if Qliclabs receives complaints concerning any
            illegality or infringement of third party rights in such content. By
            using any of the Services and transmitting or publishing any content
            using such Service, you expressly consent to determination of
            questions of illegality or infringement of third party rights in
            such content by the agent designated by Qliclabs for this purpos
          </p>
          <h2>Sample files and Applications </h2>
          <p>
            Qliclabs may provide sample files and applications for the purpose
            of demonstrating the possibility of using the Services effectively
            for specific purposes. The information contained in any such sample
            files and applications consists of random data. Qliclabs makes no
            warranty, either express or implied, as to the accuracy, usefulness,
            completeness or reliability of the information or the sample files
            and applications.{" "}
          </p>
          <h2>Trademark </h2>
          <p>
            Qliclabs, Qliclabs logo, the names of individual Services and their
            logos are trademarks of Qliclabs Technologies Private Limited. You
            agree not to display or use, in any manner, the Qliclabs trademarks,
            without Qliclabs’ prior permission.{" "}
          </p>
          <h2>Disclaimer of Warranties </h2>
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS
            AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN
            AS-IS-AND-AS-AVAILABLE BASIS. QLICLABS EXPRESSLY DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. QLICLABS MAKES NO WARRANTY THAT
            THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE.
            USE OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE
            SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
            TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF THE
            SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
            INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM QLICLABS,
            ITS EMPLOYEES OR REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT
            EXPRESSLY STATED IN THE AGREEMENT.{" "}
          </p>
          <h2>Limitation of Liability </h2>
          <p>
            YOU AGREE THAT QLICLABS SHALL, IN NO EVENT, BE LIABLE FOR ANY
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER
            LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS
            INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR
            OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR USE OF OR INABILITY TO
            USE THE SERVICE, EVEN IF QLICLABS HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE.{" "}
          </p>
          <h2>Indemnification </h2>
          <p>
            You agree to indemnify and hold harmless Qliclabs, its officers,
            directors, employees, suppliers, and affiliates, from and against
            any losses, damages, fines and expenses (including attorney's fees
            and costs) arising out of or relating to any claims that you have
            used the Services in violation of another party's rights, in
            violation of any law, in violations of any provisions of the
            Agreement , or any other claim related to your use of the Services,
            except where such use is authorized by Qliclabs.{" "}
          </p>
          <h2>Suspension and Termination </h2>
          <p>
            We may suspend your user account or temporarily disable access to
            whole or part of any Service in the event of any suspected illegal
            activity, extended periods of inactivity or requests by law
            enforcement or other government agencies. Objections to suspension
            or disabling of user accounts should be made to support@qliclabs.com
            within thirty days of being notified about the suspension. We may
            terminate a suspended or disabled user account after thirty days. We
            will also terminate your user account on your request.{" "}
          </p>
          <p>
            In addition, we reserve the right to terminate your user account and
            deny the Services upon reasonable belief that you have violated the
            Agreement and to terminate your access to any Beta Service in case
            of unexpected technical issues or discontinuation of the Beta
            Service. You have the right to terminate your user account if
            Qlilcabs breaches its obligations under this Agreement and in such
            event, you will be entitled to prorated refund of any prepaid fees.
            Termination of user account will include denial of access to all
            Services, deletion of information in your user account such as your
            email address and password and deletion of all data in your user
            account.{" "}
          </p>
          <h2>Modification of Terms of Service </h2>
          <p>
            We may modify this Agreement upon notice to you at any time through
            a service announcement or by sending email to your primary email
            address. If we make significant changes to the Agreement that affect
            your rights, you will be provided with at least 30 days advance
            notice of the changes by email to your primary email address. You
            may terminate your use of the Services by providing Qliclabs notice
            by email within 30 days of being notified of the availability of the
            modified Agreement.{" "}
          </p>
          <p>
            The User can review the latest version of the Terms of Service at
            any time at this page. Qlilcabs reserves the right to update, change
            or replace any part of these Terms of Service by posting updates
            and/or changes to Qlilcabs application, website or platform. It is
            the User’s responsibility to check the said link periodically for
            changes. Following the posting of any changes, the User’s continued
            use of or access to the Mobile Application, Desktop Application,
            Website, Store, Platform or any other products or services from
            Qlilcabs, constitutes acceptance of such revised Terms of Service.{" "}
          </p>
          <h2>End Of Terms of Service </h2>
          <p>
            If you have any questions or concerns regarding this Agreement,
            please contact us at{" "}
            <a href="mailto:support@qliclabs.com">
              <span>support@qliclabs.com</span>
            </a>{" "}
          </p>
        </div>
        <div></div>
      </div>
      <MainFooter />
    </>
  );
};

export default Terms;
