import React, { Component } from "react";
import "../core-ui/Home.css";
import "../core-ui/tablet_mediaquery.css";
import "../core-ui/mobile_mediaquery.css";
import "../core-ui/laptop_mediaquery.css";
import "../core-ui/iphone_mediaquery.css";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimated: false,
      isShrunk: false,
      isSlideUp: false,
    };
  }
  handleClick = () => {
    this.setState((prevState) => ({
      isShrunk: !prevState.isShrunk,
    }));
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isAnimated: true });
    }, 2000);

    // setTimeout(() => {
    //   const contentSection = document.querySelector(".content_cat_partners_2");
    //   const h2Element = document.querySelector(".centered");
    //   const rowElement = document.querySelector(".cat_partners_2_row");
    //   const sliderElement = document.querySelector(".Slider");

    //   if (contentSection && h2Element && rowElement && sliderElement) {
    //     contentSection.style.height = "auto";
    //     h2Element.style.opacity = "1";
    //     h2Element.style.transform = "translateY(-50%)";
    //     rowElement.style.transform = "translateY(-50%)";
    //     sliderElement.style.transform = "translateY(-50%)";
    //   }
    // }, 2000);

    // left0girl & right-girl

    // const optionsFeatures1 = {
    //   root: null,
    //   rootMargin: '0px',
    //   threshold: 1, // Change the threshold value as needed (0.5 means 50% of the section should be visible)
    // };

    // const observerFeatures1 = new IntersectionObserver((entries) => {
    //   const isVisible = entries[0].isIntersecting;
    //   if (isVisible) {
    //     this.setState({ isAnimated: true });
    //     observerFeatures1.unobserve(entries[0].target);
    //   }
    // }, optionsFeatures1);

    // const sectionFeatures1 = document.querySelector('.features_1');
    // if (sectionFeatures1) {
    //   observerFeatures1.observe(sectionFeatures1);
    // }

    // // feature 2
    // const optionsFeatures2 = {
    //   root: null,
    //   rootMargin: '0px',
    //   threshold: 1, // Change the threshold value as needed (0.5 means 50% of the section should be visible)
    // };

    // const observerFeatures2 = new IntersectionObserver((entries) => {
    //   const isVisible = entries[0].isIntersecting;
    //   if (isVisible) {
    //     this.setState({ isAnimated: true });
    //     observerFeatures2.unobserve(entries[0].target);
    //   }
    // }, optionsFeatures2);

    // const sectionFeatures2 = document.querySelector('.features_2');
    // if (sectionFeatures2) {
    //   observerFeatures2.observe(sectionFeatures2);
    // }

    // // content partner...
    // const optionsContentPartners2 = {
    //   root: null,
    //   rootMargin: '0px',
    //   threshold: 0.5, // Change the threshold value as needed (0.5 means 50% of the section should be visible)
    // };

    // const observerContentPartners2 = new IntersectionObserver((entries) => {
    //   const isVisible = entries[0].isIntersecting;
    //   if (isVisible) {
    //     this.setState({ isAnimated: true });
    //     observerContentPartners2.unobserve(entries[0].target);
    //   }
    // }, optionsContentPartners2);

    // const sectionContentPartners2 = document.querySelector('.content_cat_partners_2');
    // if (sectionContentPartners2) {
    //   observerContentPartners2.observe(sectionContentPartners2);
    // }
  }

  // handleDivClick = () => {
  //   this.setState({ isSlideUp: true });
  //   const start = performance.now();

  //   const animate = (timestamp) => {
  //     const progress = timestamp - start;

  //     if (progress < 1000) {
  //       // Continue the animation until 1000ms (1 second)
  //       this.setState({ isSlideUp: true });
  //       requestAnimationFrame(animate);
  //     } else {
  //       // Animation complete after 1 second
  //       this.setState({ isSlideUp: true });
  //     }
  //   };

  //   requestAnimationFrame(animate);
  // };

  //  imageRef = React.createRef();

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.isSlideUp !== this.state.isSlideUp) {
  //     if (this.state.isSlideUp) {
  //       // Add the slide-up class to the image when the animation starts
  //       this.imageRef.current.classList.add('slide-up');
  //     } else {
  //       // Remove the slide-up class from the image when the animation ends
  //       this.imageRef.current.classList.remove('slide-up');
  //     }
  //   }
  // }

  render() {
    const { isAnimated } = this.state;
    const { isShrunk } = this.state;

    return (
      <>
        <div className="scroll-body">
          <div className="scrolling-container">
            <div className="banner section">
              <div className="header-nav">
            <div className="header-container">
            <div className="logo">
                  <img src="images/img_epriselogo.png" alt="eprise-logo" />
              </div>
                  <div className="header-section">
				  <a href="https://assets.qliclabs.com/eprise_webapp/assets/images/pdf/Eprise+Features+Pricing+v1.0+wef+01+Apr2024.pdf" target="_blank" rel="noopener noreferrer">
                      <button className="btn header-btn">Brochure</button>
                  </a>

                  <a href="https://eprise.biz/web/index.html#/login" target="_blank" rel="noopener noreferrer">
                      <button className="btn header-btn">Login</button>
                  </a>
                  <Link to="/demo">
                      <button className="btn header-btn">Request a Demo</button>
                  </Link>
              </div>
            </div>
        </div>

              {/* <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between max-w-[1688px] mt-[66px] mx-auto md:px-5 w-full"> */}
              {/* <div className="flex md:flex-1 flex-col gap-[38px] items-start justify-start md:mt-0 mt-[75px] w-[39%] md:w-full"> */}
              <div className="home">
                <div className="home-container">
                  <div className="left-panel">
                    <img
                      className="slide-up-element"
                      src="images/img_epriselogo2.png"
                      alt="Eprise Logo"
                    />
                    <h2 className="slide-up-element">
                      Streamline Your <br /> Business, <br /> Effortlessly.
                    </h2>

                    <p className="slide-up-element">
                      Simplify Your Finances. Unleash Your Business Potential.
                      <br />
                      Discover the Power of our All-in-One Accounting Platform.
                    </p>
                    
                    <Link to="/demo">
                      <button className="btn home-btn slide-up-element">
                        Request a Demo
                      </button>
                    </Link>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.qliclabs.eprise"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                    <img
                      className="img-googleplay slide-up-element"
                      src="images/googleplay.png"
                      alt="googleplay"

                    />
                    </a>
                  </div>
                  <div className="right-panel">
                  {/* <video src="https://d2pq04iif3crbj.cloudfront.net/eprise_webapp/assets/videos/homepagevideo.mp4" type="video/mp4" className="right-panel-element" width="770" height="650"  autoPlay="autoPlay" loop>
                  </video> */}
                    <img
                      className="right-panel-element"
                      src="images/Landingpagegiff.gif"
                      alt="Eprise Logo"
                    />
                  </div>
                  <div className="right-panel-qrcode">
                    <div className="col-3 qrcode-container">
                      <h6 className="qr-text">Scan to Download Eprise  </h6>
                      <img
                        className="qr-code"
                        src="images/codeqr.png"
                        alt="Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>

            <div
              className={`features_2 section ${isAnimated ? "animate" : ""}`}
            >
              <h2
                id="media-feature2-heading"
                className={`centered ${isAnimated ? "slideUp" : ""}`}
              >
                "Access Your Financial Data Anywhere, Anytime"
              </h2>

              <div className={`slider-feature ${isAnimated ? "slideUp" : ""}`}>
                <div className="col-12 custom-slider">
                  <div
                    id="carouselExampleIndicators"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        class="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="3"
                        aria-label="Slide 4"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="4"
                        aria-label="Slide 5"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="5"
                        aria-label="Slide 6"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="6"
                        aria-label="Slide 7"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="7"
                        aria-label="Slide 8"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="8"
                        aria-label="Slide 9"
                      ></button>
                    </div>

                    <div class="carousel-inner">
                      {/* slide 1 starts */}
                      <div class="carousel-item active">
                        <div className="container-fluid">
                          <div className="row slide-1">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s1.png"
                                alt="slide1"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                  Access Anywhere, Anytime!
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                  Enjoy quick and hassle-free billing, easily
                                  send party statements, request payments, and
                                  generate reports, ensuring seamless control
                                  over your business operations.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* slide 2 starts */}
                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-2">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s2.png"
                                alt="slide2"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">GST Compliant</h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                  Simplify the process of filing GST returns.
                                  Upload all supply details to the GST portal,
                                  ensuring quick and efficient filing. Avoid
                                  penalties, meet your tax obligations on time.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>

                      {/* slide 3 starts */}
                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-3">
                            <div className="col-6 ">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s3.png"
                                alt="slide3"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                Reports and Insights at Your Fingertips
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                Our user-friendly interface allows you to effortlessly view reports—no technical expertise is needed. Instantly share reports at any time, enhancing collaboration and informed decision-making.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>

                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-4">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s4.png"
                                alt="slide4"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                  Transaction Reconciliation
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                Streamline your payment reconciliation through automated processes, saving your valuable time. Seamlessly manage bulk UPI receipts, allowing users to focus on essential tasks.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>

                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-5">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s5.png"
                                alt="slide5"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                  E-invoice & E-way bills made easy.
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                  Embrace seamless operations with E-waybills
                                  and E-invoices. Boost efficiency, ensure
                                  compliance, and streamline your business
                                  effortlessly. Experience excellence today!
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>

                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-6">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s6.png"
                                alt="slide6"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                  Managing Orders Efficiently
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                  Effortlessly boost sales, secure payments,
                                  optimize routes. Real-time tracking empowers
                                  sales decisions. Elevate sales strategies with
                                  streamlined order management.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>
                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-7">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s7.png"
                                alt="slide7"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                Receivables and Payables Management
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                  Experience automated payment reminder
                                  notifications for enhanced efficiency and
                                  timely transactions. Simplify your financial
                                  tasks with ease and precision.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>
                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-8">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s8.png"
                                alt="slide8"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                  Securely Store Your Business Documents
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                  Keep all your company-related documents, such
                                  as MCA filings, invoices, and registration
                                  certificates, organized and secure. You can
                                  access them anytime, anywhere.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>
                      <div class="carousel-item">
                        <div className="container-fluid">
                          <div className="row slide-9">
                            <div className="col-6">
                              <img
                                id="media-slider-image"
                                className="slide-images"
                                src="images/s9.png"
                                alt="slide1"
                              />
                            </div>
                            <div className="col-6 slide-header-text">
                              <div className="row text1">
                                <h1 id="media-text1-slider">
                                  Collaborative Client-Vendor Portal
                                </h1>
                              </div>
                              <div className="row text2">
                                <div id="media-text2-slider">
                                  No more follow-ups and waiting! Access
                                  reports, monitor payments, and enhance
                                  transparency effortlessly. Save valuable time,
                                  build trust, offer real-time reports and
                                  analytics securely.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src="..." class="d-block w-100" alt="..."> */}
                      </div>
                    </div>
                    {/* previous controller */}
                    <button
                      class="carousel-control-prev justify-content-start "
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    {/* next controller */}
                    <button
                      class="carousel-control-next justify-content-end"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`content_cat_partners_2 section ${
                isAnimated ? "animate" : ""
              }`}
            >
              <h2
                id="media-card-header-text"
                class={`centered ${isAnimated ? "slideUp" : ""}`}
              >
                "Financial bliss,at the click of a button."
              </h2>
              <div
                className={`cat_partners_2_row ${isAnimated ? "slideUp" : ""}`}
              >
                <div className="card" id="media-card">
                  <div className="card-body">
                    <img
                      id="media-card-img"
                      className="qr-code"
                      src="images/text1.png"
                      alt="Compliance"
                    />
                  </div>
                  <div className="card-footer-">
                    <p id="media-card-text">Compliance</p>
                  </div>
                </div>
                <div className="card" id="media-card">
                  <div className="card-body">
                    <img
                      id="media-card-img"
                      className="qr-code"
                      src="images/text2.png"
                      alt="Compliance"
                    />
                  </div>
                  <div className="card-footer-">
                    <p id="media-card-text">Accounting</p>
                  </div>
                </div>
                <div className="card" id="media-card">
                  <div className="card-body">
                    <img
                      id="media-card-img"
                      className="qr-code"
                      src="images/text3.png"
                      alt="Compliance"
                    />
                  </div>
                  <div className="card-footer-">
                    <p id="media-card-text">Technology</p>
                  </div>
                </div>
              </div>
              <div class={`partner-slider ${isAnimated ? "slideUp" : ""}`}>
                <Marquee speed={100}>
                  <div className="logos-marquee-slider">
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/1.png"
                        alt="logo1"
                      />
                    </div>
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/2.png"
                        alt="logo1"
                      />
                    </div>
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/3.png"
                        alt="logo1"
                      />
                    </div>
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/4.png"
                        alt="logo1"
                      />
                    </div>
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/5.png"
                        alt="logo1"
                      />
                    </div>
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/6.png"
                        alt="logo1"
                      />
                    </div>
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/7.png"
                        alt="logo1"
                      />
                    </div>
                    <div>
                      <img
                        className="logo-slide-images"
                        src="images/8.png"
                        alt="logo1"
                      />
                    </div>
                  </div>
                </Marquee>
              </div>
            </div>

            <div className="features_1 section">
              <h2>"Access Your Financial Data Anywhere, Anytime"</h2>

              {/* <div className="left-girl-container"></div>
              <div className="right-girl-container"></div> */}
              <img
                className={`left-girl ${
                  isAnimated ? "slide-in-out-left-girl-animation" : ""
                }`}
                src="images/Girl_Left1.png"
                alt="Logo"
              />
              <img
                className="right-girl"
                //  className={`right-girl ${isAnimated ? "slide-in-out-right-girl-animation" : ""}`}
                src="images/Girl_Right1.png"
                alt="Logo"
              />
            </div>

            <div className="content_cat_partners_1 section">
            <div className="grid-text-container">
              <div className="left-grid-container">
                <div className={`left-grid-section ${isShrunk ? "shrunken-left" : ""}`}>
                  <div className="grid-col-3">
                    <div className="etext-section first-child">
                      <p id="blinkset1">Profit and Loss</p>
                    </div>
                    <div className="etext-section ">
                      <p id="blinkset2">GSTR 2B Reconciliation</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset3">Stock Ledger</p>
                    </div>
                  </div>
                  <div className="grid-col-2">
                    <div className="etext-section first-child">
                      <p id="blinkset3">Subscription Based Billing</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset1">Barcode Label Printing</p>
                    </div>
                  </div>
                  <div className="grid-col-3">
                    <div className="etext-section first-child">
                      <p id="blinkset1">Cheque Printing Form</p>
                    </div>
                    <div className="etext-section ">
                      <p>Balance Sheet</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset3">View Transaction </p>
                    </div>
                  </div>
                  <div className="grid-col-3">
                    <div className="etext-section first-child">
                      <p id="blinkset3">Stock Report</p>
                    </div>
                    <div className="etext-section ">
                      <p id="blinkset2">Search with IFSC</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset1">Generate Receipts</p>
                    </div>
                  </div>
                  <div className="grid-col-2">
                    <div className="etext-section first-child">
                      <p id="blinkset1">Production vs Consumption</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset3">Sales Team Management</p>
                    </div>
                  </div>
                  <div className="grid-col-2">
                    <div className="etext-section first-child">
                      <p id="blinkset3">Payment Integrations</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset1">E-Way Bill/ E-Invoice</p>
                    </div>
                  </div>

                </div>
              </div>
              <div className="middle-grid-container">
                <img className="middle-grid-image" src="images/middle-grid-giff.gif" alt="grid"
                  // onClick={this.handleClick}

                  />
              </div>
              <div className="right-grid-container">
              <div className={`right-grid-section ${isShrunk? "shrunken-right" : ""}`}>
                <div className="grid-col-3">
                  <div className="etext-section first-child">
                    <p id="blinkset3">Banking Reconciliation</p>
                  </div>
                  <div className="etext-section ">
                    <p>GSTR 1</p>
                  </div>
                  <div className="etext-section last-child">
                    <p id="blinkset1">Order Booking</p>
                  </div>
                </div>
                <div className="grid-col-3">
                  <div className="etext-section first-child">
                    <p id="blinkset1">Trial Balance</p>
                  </div>
                  <div className="etext-section ">
                    <p id="blinkset2">Bill Of Materials</p>
                  </div>
                  <div className="etext-section last-child">
                    <p id="blinkset3">Smart Tags</p>
                  </div>
                </div>
                <div className="grid-col-3">
                  <div className="etext-section first-child">
                    <p id="blinkset3">Party wise Report</p>
                  </div>
                  <div className="etext-section ">
                    <p>Debit Note</p>
                  </div>
                  <div className="etext-section last-child">
                    <p id="blinkset1">Inventory Management</p>
                  </div>
                </div>

                <div className="grid-col-2">
                    <div className="etext-section first-child">
                      <p id="blinkset1">Payment Collections</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset3">Debtors/Creditors Report</p>
                    </div>
                </div>
                <div className="grid-col-3">
                  <div className="etext-section first-child">
                    <p id="blinkset3">API Integrations</p>
                  </div>
                  <div className="etext-section ">
                    <p id="blinkset2">Client/Vendor Portal</p>
                  </div>
                  <div className="etext-section last-child">
                    <p id="blinkset1">Credit Note</p>
                  </div>
                </div>
                <div className="grid-col-2">
                    <div className="etext-section first-child">
                      <p id="blinkset1">Tally Sync/Integrations</p>
                    </div>
                    <div className="etext-section last-child">
                      <p id="blinkset3">Invoice Designer</p>
                    </div>
                </div>


                </div>
              </div>

            </div>
              {/* <h2
              //  onClick={this.handleDivClick}
              >
                "Take control of your business's finance with E-Prise"
              </h2> */}
              {/* <div className="div-container">
                <img

                  className={`texture-logo-image ${
                    isAnimated ? "slide-up" : ""
                  }`}
                  src="images/shrinklogo.png"
                  alt="Logo"
                  onClick={this.handleClick}
                  ref={this.imageRef}
                />
                <div
                  className={`Eprise-text-container ${
                    isShrunk ? "shrunken" : ""
                  }`}

                >
                  <div className="grid-col-5">
                    <div className="etext-section first-child">
                      <p>E-Way Bill</p>
                    </div>
                    <div className="etext-section">
                      <p>Balance Sheet</p>
                    </div>
                    <div className="etext-section">
                      <p className="text-bold">API Integrations</p>
                    </div>
                    <div className="etext-section">
                      <p>Profit and Loss</p>
                    </div>
                    <div className="etext-section last-child">
                      <p className="text-bold">E-Way Bill/ E-Invoice</p>
                    </div>
                  </div>
                  <div className="grid-col-4">
                    <div className="etext-section first-child">
                      <p className="text-bold">Payment Integrations</p>
                    </div>
                    <div className="etext-section">
                      <p>Inventory Management</p>
                    </div>
                    <div className="etext-section">
                      <p>Client/Vendor Portal</p>
                    </div>
                    <div className="etext-section last-child">
                      <p className="text-bold">Order Booking</p>
                    </div>
                  </div>
                  <div className="grid-col-5">
                    <div className="etext-section first-child">
                      <p>Trial Balance</p>
                    </div>
                    <div className="etext-section">
                      <p>Invoice Designer</p>
                    </div>
                    <div className="etext-section">
                      <p className="text-bold">Banking Reconciliation</p>
                    </div>
                    <div className="etext-section">
                      <p>Credit Note</p>
                    </div>
                    <div className="etext-section last-child">
                      <p>GSTR 1</p>
                    </div>
                  </div>
                  <div className="grid-col-3">
                    <div className="etext-section">
                      <div className="grid-col-2">
                        <div className="etext-section first-child">
                          <p>Validate GSTN</p>
                        </div>
                        <div className="etext-section">
                          <p>Debit Note</p>
                        </div>
                        <div className="etext-section first-child">
                          <p className="text-bold">
                            Subscription Based Billing
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="etext-section">

                    </div>
                    <div className="etext-section">
                      <div className="grid-col-1">
                        <div className="etext-section last-child">
                          <p className="text-bold">Generate Receipts</p>
                        </div>
                        <div className="etext-section last-child">
                          <p>Cheque Printing Form</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-col-5">
                    <div className="etext-section first-child">
                      <p>Stock Report</p>
                    </div>
                    <div className="etext-section">
                      <p>Stock Ledger</p>
                    </div>
                    <div className="etext-section">
                      <p>Search with IFSC</p>
                    </div>
                    <div className="etext-section">
                      <p>Smart Tags</p>
                    </div>
                    <div className="etext-section last-child">
                      <p className="text-bold">Bill Of Materials</p>
                    </div>
                  </div>
                  <div className="grid-col-3">
                    <div className="etext-section first-child">
                      <p>Production vs Consumption</p>
                    </div>
                    <div className="etext-section">
                      <p className="text-bold">Sales Team Management</p>
                    </div>
                    <div className="etext-section last-child">
                      <p>GSTR 2B Reconcilliation</p>
                    </div>
                  </div>
                  <div className="grid-col-4">
                    <div className="etext-section first-child">
                      <p className="text-bold">Tally Sync/Integrations</p>
                    </div>
                    <div className="etext-section">
                      <p>Barcode Label Printing</p>
                    </div>
                    <div className="etext-section">
                      <p>Credit/Debit Note</p>
                    </div>
                    <div className="etext-section last-child">
                      <p className="text-bold">Payment Collections</p>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                  <div className="col-12">
                    <div className="E-text">
                      <h6 data-content="E-Way Bill">E-Way Bill</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Balance Sheet">Balance Sheet</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="API Integrations">API Integrations</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Profit and Loss">Profit and Loss</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="E-Way Bill/ E-Invoice">
                        E-Way Bill/ E-Invoice
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="E-text">
                      <h6 data-content="Payment Integrations">
                        Payment Integrations
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Inventory Management">
                        Inventory Management
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Client/Vendor Portal">
                        Client/Vendor Portal
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Order Booking">Order Booking</h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="E-text">
                      <h6 data-content="Trial Balance">Trial Balance</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Invoice Designer">Invoice Designer</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Banking Reconciliation">
                        Banking Reconciliation
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Credit Note">Credit Note</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="GSTR 1">GSTR 1</h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row horizontal-row">
                      <div className="col-4 left-texture">
                        <div className="E-text">
                          <h6 data-content="Validate GSTN">Validate GSTN</h6>
                        </div>
                        <div className="E-text">
                          <h6 data-content="Subscription Based Billing">
                            Subscription Based Billing
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 texture-main-logo">
                      <img
                        className="texture-logo-image"
                        src="images/img_epriselogo2.png"
                        alt="Logo"
                        class="img-fluid"
                      />
                    </div>

                    <div className="row horizontal-row">
                      <div className="col-4 right-texture">
                        <div className="E-text">
                          <h6 data-content="Generate Receipts">
                            Generate Receipts
                          </h6>
                        </div>
                        <div className="E-text">
                          <h6 data-content="Cheque Printing Form">
                            Cheque Printing Form
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="E-text">
                      <h6 data-content="Stock Report">Stock Report</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Stock Ledger">Stock Ledger</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Search with IFSC">Search with IFSC</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Smart Tags">Smart Tags</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Bill Of Materials">
                        Bill Of Materials
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="E-text">
                      <h6 data-content="Production vs Consumption">
                        Production vs Consumption
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Debit Note">Debit Note</h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Sales Team Management">
                        Sales Team Management
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="GSTR 2B Reconcilliation">
                        GSTR 2B Reconcilliation
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="E-text">
                      <h6 data-content="Tally Sync/Integrations">
                        Tally Sync/Integrations
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Barcode Label Printing">
                        Barcode Label Printing
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Credit/Debit Note">
                        Credit/Debit Note
                      </h6>
                    </div>
                    <div className="E-text">
                      <h6 data-content="Payment Collections">
                        Payment Collections
                      </h6>
                    </div>
                  </div>
                </div> */}
            </div>

            {/* <div className="expanding-features section">
              <h2>"Take control of your business's finances with E-Prise"</h2>
            </div> */}

            {/* <div className="blog section">
              <div className="blog-container">
                <div className="blog-top">
                  <h2>Our Blogs</h2>
                  <button className="btn blog-btn">View All Posts</button>
                </div>
                <div className="blog-body">
                  <p>
                    Boost accounting knowledge, achieve financial success.
                    Valuable insights, expert advice, practical tips for
                    informed decisions, from basics to advanced techniques.
                  </p>
                  <div className="blog-grid">
                    <div className="blog-content"></div>
                    <div className="blog-content"></div>
                    <div className="blog-content"></div>
                    <div className="blog-content"></div>
                    <div className="blog-content"></div>
                    <div className="blog-content"></div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="faq section">
              <h2 className="faq-h2">FAQs</h2>
              <div className="faq-body">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Is Eprise suitable for small businesses or only for
                        larger enterprises?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> class. This is the first
                        item's accordion body.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Can I access Eprise remotely or on mobile devices?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> class. This is the second
                        item's accordion body. Let's imagine this being filled
                        with some actual content.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Can I customize Eprise to meet my specific accounting
                        needs?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> class. This is the third
                        item's accordion body. Nothing more exciting happening
                        here in terms of content, but just filling up the space
                        to make it look, at least at first glance, a bit more
                        representative of how this would look in a real-world
                        application.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Can Eprise help with tax calculations and filing?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> class. This is the third
                        item's accordion body. Nothing more exciting happening
                        here in terms of content, but just filling up the space
                        to make it look, at least at first glance, a bit more
                        representative of how this would look in a real-world
                        application.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="download-page section">
              <div className="download-header">
                <Marquee speed={50} className="download-marquee">
                  <p>Automate your financial tasks with E-Prise. </p>
                </Marquee>
              </div>
              <div className="download-content">
                <div class="card">
                  <img src="images/card1.png" class="card-img-top" alt="..." />
                  <div class="card-body">
                    <p class="card-text">Eprise Logo</p>
                    <button class="btn btn-primary">Download</button>
                  </div>
                </div>
                <div class="card">
                  <img src="images/card2.png" class="card-img-top" alt="..." />
                  <div class="card-body">
                    <p class="card-text">Eprise Photos</p>
                    <button class="btn btn-primary">Download</button>
                  </div>
                </div>
                <div class="card">
                  <p className="img-empty"></p>
                  <div class="card-body">
                    <p class="card-text">Files</p>
                    <button class="btn btn-download">Download</button>
                  </div>
                </div>
              </div>
              <div className="download-footer">
                <p>
                  Our press kit assets (logo, product images, founder's photos)
                  are exclusive and intended for global press and news coverage,
                  including India. Prior consent is necessary for any
                  promotional or monetization use. Non-compliance may result in
                  logo removal from the public domain.
                </p>
              </div>
            </div> */}

            <div className="footer section">
              <div className="footer-top">
                <div className="row">
                  <div className="col-md-6">
                    <h2>
                      <span>Manage your Business</span>
                      <br /> Wherever You are
                    </h2>
                    <p>Sign Up For Early Access On </p>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.qliclabs.eprise"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                    <img
                      className="img-googleplay"
                      src="images/googleplay.png"
                      alt="googleplay"

                    />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <img
                      className="img-phone-footer"
                      src="images/footerphone.png"
                      alt="phone"
                    />
                    <img
                      className="img-laptop-footer"
                      src="images/footerlaptop.png"
                      alt="laptop"
                    />
                  </div>
                </div>
              </div>
              {/* footer bottom */}
              <div className="footer-bottom">
                <div className="row">
                  {/* <div className="col-md-6">
                    <p>
                      As a leading company in the realm of financial management,
                      we are committed to revolutionizing the way businesses
                      handle their accounting needs.
                    </p>
                  </div> */}
                  <div className="col-md-2">
                    <h5>Company</h5>
                    {/* <h6>About</h6> */}
                    <Link to="/privacy-policy">
                    <h6>Privacy Policy</h6>
                    </Link>
                    <Link to="/terms-and-conditions">
                    <h6>Terms & Conditions</h6>
                    </Link>
                    <Link to="/refund-and-cancellation">
                    <h6>Refund Policy</h6>
                    </Link>

                  </div>
                  <div className="col-md-5">
                    <h5>Get in Touch</h5>
                    <div className="row-p-tage">
                      <img
                        className="loc-icon"
                        src="images/l1.png"
                        alt="linledln"
                      />
                      <p>
                        IKP, K-Tech Innovation Hub, 3rd Floor, Plama Center,
                         Bejai Kapikad Road, Mangaluru, Karnataka -
                        575004.
                      </p>
                    </div>

                    <div className="row-numbers">
                      <img
                        className="loc-icon"
                        src="images/c1.png"
                        alt="linledln"
                      />
                      <h6><a href="tel:+918904052211">+918904052211</a></h6>                  
                    </div>
                  </div>
                </div>
                <div className="footer-line"></div>
                <div className="row after-footer-line">
                  <div className="col-md-5 footer-logo-stack">
                    <a
                      href="https://www.youtube.com/@qliclabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="footerimg"
                        src="images/Youtube.png"
                        alt="youtube"
                        class="img-fluid"
                      />
                    </a>

                    <a
                      href="https://www.instagram.com/qliclabs/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="footerimg"
                        src="images/Instagram1.png"
                        alt="instagram"
                        class="img-fluid"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/31312745/admin/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="footerimg"
                        src="images/in1.png"
                        alt="linledln"
                        class="img-fluid"
                      />
                    </a>

                    <a
                      href="https://twitter.com/qliclabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="footerimg"
                        src="images/Twitter1.png"
                        alt="twitter"
                        class="img-fluid"
                      />
                    </a>

					<a
                      href="https://www.threads.net/@qliclabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="footerimg"
                        src="images/threads.png"
                        alt="threads"
                        class="img-fluid"
                      />
                    </a>
                  </div>

                  <div className="col-md-4">
                    <h6>Question or feedback? We'd love to hear from you!</h6>
                    <a href="mailto:support@qliclabs.com"><h6 className="email-link">support@qliclabs.com</h6></a>

                  </div>
                </div>
                <div className="footer-line"></div>
                <div className="footer-name-line">
                  <h6>© Copyright Qliclabs Technologies Pvt. Ltd. 2024</h6>
                </div>
              </div>
            </div>

            <div className="footer-2 section">
              {/* <div className="footer-bottom">
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      As a leading company in the realm of financial management,
                      we are committed to revolutionizing the way businesses
                      handle their accounting needs.
                    </p>
                  </div>
                  <div className="col-md-1">
                    <h5>Company</h5>
                    <h6>About</h6>
                    <h6>Career</h6>
                    <h6>Policy</h6>
                  </div>
                  <div className="col-md-5">
                    <h5>Get in Touch</h5>
                    <p>
                      Ikp, Ktech Innovation Hub, 3rd Floor, Plama Center,
                      Mangaluru, Bejai Kapikad Road, Bejai, Mangalore - 575004.
                    </p>
                    <div className="row-numbers">
                      <h6>+918970452630</h6>
                      <h6>+918970452630</h6>
                    </div>
                  </div>
                </div>
                <div className="footer-line"></div>
                <div className="row">
                  <div className="col-md-5 footer-logo-stack">
                    <img
                      className="footerimg"
                      src="images/FaceBook.png"
                      alt="facebook"
                    />
                    <img
                      className="footerimg"
                      src="images/Instagram.png"
                      alt="instagram"
                    />
                    <img
                      className="footerimg"
                      src="images/in.png"
                      alt="linledln"
                    />
                    <img
                      className="footerimg"
                      src="images/Twitter.png"
                      alt="twitter"
                    />
                  </div>

                  <div className="col-md-4">
                    <h6>Question or Feedback ? We'd Love here from you !</h6>
                    <h6>Support@Qliclabs.com</h6>
                  </div>
                </div>
                <div className="footer-line"></div>
                <div className="footer-name-line">
                  <h6>© Copyright Qliclabs Technologies Pvt. Ltd. 2024</h6>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
