import React, { useEffect } from "react";
import "../core-ui/Privacy.css";
import Header from "../components/Header";
//import Footer from "../components/Footer";
import MainFooter from "../components/MainFooter";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="privacy-policy-container">
        <div></div>
        <div className="privacy-policy-content">
          <h1>Privacy Policy</h1>
          <div className="horizontal-line"></div>
          <p>Last updated on: 01st September 2024 </p>
          <h2>Introduction</h2>
          <p>
            This Privacy Policy (“Policy”) describes Qliclabs Technologies
            Private Limited's (hereinafter “Qliclabs”), company incorporated
            under the Companies Act, 2013 having CIN U72900KA2019PTC121576 with
            its office at Flat No.502, 5th Floor, "Sri Devidayal Canopus",
            Kodical Main Road, Ashoknagar PO, Derebail Village, Mangaluru-575006
            and Development center at K Tech Innovation Hub IKP, 3rd Floor,
            Plama Center, Bejai - Kapikad Road, Mangaluru -575004 , policies and
            procedures on the collection, use and disclosure of a User's
            information pursuant to your use of the Mobile Application, Desktop
            Application, Web based application, Store, Website, platform and any
            other services offered (hereinafter “Eprise”), whether existing now
            or in future. Qliclabs is committed to ensuring that any use or
            sharing of your information with anyone shall be one only in
            accordance with the procedures described in this Privacy Policy.
          </p>
          <p>
            Please read this Privacy Policy before using the Eprise or
            submitting any personal information. By using Eprise, it shall be
            deemed that the Users are expressly consenting to be bound by the
            terms and conditions described in this Privacy Policy.
          </p>
          <p>
            However, in case the User does not intend to share some of the
            Information as referred herein below for use by Qliclabs as stated,
            the User is free to opt-out or not use the Eprise by deleting the
            Account and in such a case, are not required to give consent as per
            the present Privacy Policy.
          </p>
          <p>
            In the case in future it is felt that the Information or a part
            thereof as collected should not be used or should be deleted,
            consent granted in the present Policy can be withdrawn, by writing
            to the Company seeking to withdraw the consent so granted or
            deleting the Account, as the case may be. However, in case the
            consent for the Company to use the Information as provided in the
            present Policy is withdrawn, the Products and Services cannot be
            offered to the User by the Company.
          </p>

          <h2>Modification of Privacy Policy </h2>
          <p>
            These policies may be updated from time to time in the future. The
            User should agree to review our privacy policies regularly by
            visiting this page. Continued access to or use of the service will
            mean that the User agrees to the change.
          </p>
          <h2>Applicability</h2>
          <p>
            When the privacy statement Applies, its liability to the User is
            limited to those who have downloaded the Eprise application and/or
            logged in by clicking on the “I Agree/Login” button for Terms of
            Services in or has explicitly consented to the policy on any other
            product/service page offered to the User.
          </p>
          <h2>
            How information is collected, used, disclosed, shared and stored{" "}
          </h2>
          <ol type="1">
            <li>
              The Company collects, uses, discloses, shares and transfers
              Information, including but not limited to User content, other
              content, communications, information etc. provided by Users when
              the Users access or use the Eprise. By use of the Eprise , express
              consent and permission is granted to the Company for having access
              to, using, processing, disclosing, sharing and transferring of
              information in accordance with this Policy and the Terms of
              Services.
            </li>
            <li>
              For the purpose of these Terms, the term “Personal Information”
              shall mean any information provided by the User and shall include,
              without limitation User content, any and all documents, details,
              information etc. shared with the Qliclabs by the User and the One
              Time Password (OTP) received by the User from the government
              authorities/Goods and Service Tax authority/ any other entity for
              authenticating/verifying in furtherance to the Terms of Service.
              For the purpose of these Terms, the term “Non-Personal
              Information” shall mean information that does not specifically
              identify an individual but includes information from such as the
              type of internet browser used, mobile device used, computer or
              mobile device's unique device ID, Internet Service Provider (ISP)
              and Internet Protocol (IP) address. The Company may also collect
              Non-Personal Information that is provided by the users. Personal
              and Non-Personal Information together is referred to as
              “Information”. The Company may collect Information when
              registering or opening an account in Eprise, when the features or
              the Products and Services are used, when the User shares the User
              content with the Company and when the User interacts with the
              customer support team including the details of the message or
              chats etc
            </li>
            <li>
              The Company may use cookies to monitor usage including, without
              limitation, to provide useful features to simplify experiences for
              the User on the Eprise, like remembering login ID and certain
              Personal Information and to deliver relevant content based on
              preferences, usage patterns and location.
            </li>
            <li>
              There could also arise a requirement of providing credit/debit
              card when the User is required for payments as per the Terms of
              Service. The Company or any third party providing services related
              to payment may use this information, such as account and
              authentication information and billing, delivery and contact
              details. The Company shall in no case be liable for any loss or
              damage sustained by the User arising out of any disclosure
              (inadvertent or otherwise) of any information relating to bank
              account, credit or debit card details in the course of any online
              transactions or payments made relating to Government Fees and/or
              Facilitation Fees.
            </li>
            <li>
              The Company collects information about the User's use of the
              Eprise, such as the features used, any activity carried out, the
              actions that are taken, the time, frequency, and duration of the
              said activities.
            </li>
            <li>
              The Company is required to collect information from and about the
              computers, tablets, phones and other web-connected devices used
              and the Company then combines this information across different
              devices that are used. Information that the Company obtains from
              these devices includes:
            </li>
            <ol type="a">
              <li>
                Information including but not limited to the operating system,
                hardware and software versions, battery level, signal strength,
                available storage space, browser type, app and file names and
                types, and plugins, name of mobile operator or ISP, language,
                time zone, mobile phone number, IP address, connection speed
                and, in some cases, information about other devices that are
                nearby or on user network etc.
              </li>
              <li>
                Information including but not limited to access to beacons and
                mobile phone masts, access to GPS location, network, camera or
                photos, data from cookies stored on user devices, storage space
                or disk on the device etc.
              </li>
              <li>
                Location-related information - such as current location to
                provide, personalized and improved use of the Eprise.
                Location-related information can be based on things such as
                precise device location, IP addresses etc.
              </li>
            </ol>
          </ol>
          <h2>Collection of SMS information</h2>
          <p>
            We may collect, read and store SMS relating to the payment from your
            bank accounts for tracking of the expense information. The user may
            however not give consent for sharing the information and not avail
            the feature of tracking expenses. We may also collect SMS data only
            for the purpose of OTP verification within our application. Only
            when you grant permission to access SMS, the information will be
            collected.
          </p>
          <h2>Collection of device location and device information </h2>
          <p>
            Information that Eprise collects, and its usage depends on how you
            manage your privacy controls on your device. When you install the
            App, we store the information we collect with unique identifiers
            tied to the device you are using. We collect information from the
            device when you download and install the App and explicitly seek
            permissions from You to get the required information from the
            device. The information we collect from your device includes the
            hardware model, build model, RAM, storage; unique device identifiers
            like IMEI, serial number, SSAID; SIM information that includes
            network operator, roaming state, MNC and MCC codes, WIFI information
            that includes MAC address and mobile network information to uniquely
            identify the devices and ensure that no unauthorized device acts on
            your behalf to prevent frauds.
          </p>
          <h2>Collection of installed applications </h2>
          <p>
            We collect a list of the installed applications' metadata
            information which includes the application name, package name,
            installed time, updated time, version name and version code of each
            installed application on your device.
          </p>
          <h2>Storage </h2>
          <p>
            We require storage permission to facilitate the seamless functioning
            of certain features within the Application. The storage permission
            enables the Application to locally store necessary files, such as
            temporary data and settings, to enhance user experience and provide
            efficient services.
          </p>
          <h2>Camera </h2>
          <p>
            Me may require device's camera permission to enable specific
            features that require capturing or uploading images or videos or
            scanning barcode or QR code. We want to assure you that your privacy
            and data security remain our utmost priority
          </p>
          <h2>Collection of other non-personal information </h2>
          <p>
            We automatically track certain information about you based upon your
            behavior on our Platform. We use this information to do internal
            research on our users' demographics, interests, and behavior to
            better understand, protect and serve our users and improve our
            services. This information is compiled and analyzed on an aggregated
            basis. We also collect your Internet Protocol (IP) address and the
            URL used by you to connect your computer to the internet, etc. This
            information may include the URL that you just came from (whether
            this URL is on our Website or not), which URL you next go to
            (whether this URL is on our Website or not), your computer browser
            information, and your IP address. Cookies are small data files that
            a Website stores on Your computer. We will use cookies on our
            Website similar to other lending websites/apps and online
            marketplace websites/apps. Use of this information helps Us identify
            You in order to make our Website more user friendly. Most browsers
            will permit You to decline cookies but if You choose to do this it
            might affect service on some parts of Our Website. If you choose to
            make a purchase through the Platform, we collect information about
            your buying behaviour. We retain this information as necessary to
            resolve disputes, provide customer support and troubleshoot problems
            as permitted by law. if you send us personal correspondence, such as
            emails or letters, or if other users or third parties send us
            correspondence about your activities or postings on the Website, we
            collect such information into a file specific to you.
          </p>
          <h2>Link to Third Party </h2>
          <p>
            We ensure that our third-party service provider takes extensive
            security measures in order to protect your personal information
            against loss, misuse or alteration of the data. Our third-party
            service provider employs separation of environments and segregation
            of duties and has strict role-based access control on a documented,
            authorized, need to-use basis. The stored data is protected and
            stored by application-level encryption. They enforce key management
            services to limit access to data. Furthermore, our registered third
            party service provider provides hosting security - they use industry
            leading anti-virus, anti-malware, intrusion prevention systems,
            intrusion detection systems, file integrity monitoring, and
            application control solutions
          </p>
          <h2>How Eprise uses user data </h2>
          <p>The Company may use the Information for:</p>
          <ol type="1">
            <li>
              Providing access to the Eprise and/or use of the Products and
              Services.{" "}
            </li>
            <li>Manage Account with the Qliclabs. </li>
            <li>
              Authentication by using One Time Password received from the for
              the Purpose including but not limited to government
              authorities/good and service tax authority for
              authenticating/verifying or any other product/service availed by
              the user from time to time on Qliclabs.{" "}
            </li>
            <li>
              Send SMS for authenticating transactions via One Time Password
              (OTP) etc.{" "}
            </li>
            <li>
              Conduct a general survey or analysis of Accounts or otherwise.{" "}
            </li>
            <li>
              Develop, test and improve the Eprise and/or Products and Services,
              testing and troubleshooting new products and features, removing
              any glitches including technical problems, improving the
              experience of navigating and carrying out transactions and
              improving the services and content, Products and Service on the
              Eprise.{" "}
            </li>
            <li>
              Based on Information, share sponsored content and show ads,
              including relating to third parties, that the Company thinks will
              be relevant.{" "}
            </li>
          </ol>
          <h2>Sharing of personal information </h2>
          <p>
            The Company may disclose or share Personal Information including but
            not limited to User Content in the following ways:{" "}
          </p>
          <ol type="1">
            <li>
              Disclosure or sharing with government authorities including but
              not limited to the Goods and Service Tax Authority, MSME
              Authority, FSSAI Authority. Information including Personal
              Information may be shared with and disclosed to government
              authorities including but not limited to the Goods and Service Tax
              Authority, MSME Authority, FSSAI Authority, in order to enable
              providing the Products and Services.
            </li>
            <li>
              Disclosure to Government authorities or as required by law
              information may be shared with Government and/or statutory
              authorities or under court orders or other legal processes; to
              establish or exercise legal rights; to defend against legal
              claims; or as otherwise required by law. The Company warrants that
              such information will be disclosed only in accordance with
              applicable directions, instructions, laws and regulations.{" "}
            </li>
            <li>
              Disclosure to third parties the Company may provide, disclose and
              share information including Personal Information that is collected
              to third-party service providers, database service providers,
              backup and disaster recovery service providers, email service
              providers and also make it accessible to technology partners,
              subsidiaries and affiliates, including outside India, to help
              ensure availability of Eprise platform and providing the Products
              and Services{" "}
            </li>
            <li>
              Disclosure to prospective Acquirers The Company may disclose
              information including Personal Information to an acquirer,
              assignee or other successor entity in connection with a sale,
              merger, or reorganization of all or substantially all of the
              equity or business of the Company.{" "}
            </li>
            <li>
              Disclosure to facilitate payment on Payment Gateways in order to
              process payment transactions, the Company may direct to a payment
              gateway service provider of its own choice, which may have access
              to the Personal Information provided while making such payment.{" "}
            </li>
          </ol>
          <h2>Protection of information </h2>
          <p>
            The Company, on a best effort basis, shall attempt to take adequate
            measures to protect the security of Personal Information and to
            ensure that the choices for its intended use are honoured. The
            Company also takes adequate precautions, on a best effort basis, to
            protect data from loss, misuse, unauthorized access or disclosure,
            alteration or destruction.
          </p>
          <p>
            The Company uses industry standards of physical, technical and
            administrative security measures to keep Information, on best effort
            basis, secure although is unequivocally understood and agreed that
            the same cannot be foolproof since no security system is impassable
            and the Company does not guarantee that the Information may be
            absolutely free from invasion.{" "}
          </p>
          <p>
            Please note that e-mails, messaging and means of communication with
            the Company may not be encrypted, and therefore the Company advises
            not to communicate any confidential information through these means.{" "}
          </p>
          <p>
            The Company is not responsible for any breach of security or for any
            actions or inactions of any Users or other third parties including
            but not limited to government authorities that receive Information.{" "}
          </p>
          <p>
            The User is solely responsible for maintaining the confidentiality
            of its username and password. To ensure the safety of Personal
            Information, it is advised that the username and password should not
            be shared with anyone. The Company would not be liable or
            responsible for any breach of this covenant for any reason.{" "}
          </p>
          <p>
            The Company would retain Personal Information only as long as the
            entity or User to whom the said Personal Information belongs has an
            active Account or as permitted/required by applicable law.
            Generally, Personal Information, upon deletion of a related Account,
            is also deleted unless the same is required to comply with any legal
            requirements, fraud prevention or assistance in any enquiry, for
            resolving disputes, to protect interests of the Company, to continue
            to develop and improve the Products and Services.{" "}
          </p>
          <h2>Grievance redressal mechanism </h2>
          <p>
            In accordance with the Information Technology Act, 2000 and Rules
            made thereunder, the contact details for raising grievances or
            obtaining any further information or enquiry, if any are provided
            below:{" "}
          </p>
          <p>
            Address: K Tech Innovation Hub IKP, 3rd Floor, Plama Center, Bejai -
            Kapikad Road, Mangaluru -575004
          </p>

          <p>
            E-mail :{" "}
            <a href="mailto:support@qliclabs.com">
              <span>support@qliclabs.com</span>
            </a>{" "}
          </p>
          <p>
            Contact number:{" "}
            <a href="tel:+918904052211">
              <span>+91 8904052211</span>
            </a>{" "}
          </p>
        </div>

        <div></div>
      </div>
      <MainFooter />
    </>
  );
};

export default Privacy;
