import React, { useState  } from "react";
import "../core-ui/DemoPage.css";
import ReCAPTCHA from "react-google-recaptcha";
// import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "../core-ui/DemoPgemediaqueries/Demo_laptop.css";
import "../core-ui/DemoPgemediaqueries/Demo_tab.css";
import "../core-ui/DemoPgemediaqueries/Demo_phone.css";
import { Link } from "react-router-dom";
import Axios from '../axios'

const DemoPage = () => {

  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState(false);

  const [inputValue, setInputValue] = useState({
    name: "",
    client_users_phone: "",
    email: "",
    company: "",
    comments: "",
    contact_us_secret_key:'6LcaPT0jAAAAAHBo-YA2tEwEdyFhSku3tFT9oX9A',
    contact_us_response:""
  });



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };
    

  const handleSubmit =  async  (event) => {
    event.preventDefault();
  
  try {
    
    await Axios.post(
      '/auth/early_access', 
      inputValue , 
      {withCredentials: false},
      { headers: { 'Accept': 'application/json'} },
      { cors: { "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type" } }
    );
   
    setMessage({message: 'Form Submitted Successfully'})
    setShowPopup(true);
    // Automatically close the popup after 5 seconds
    // setTimeout(() => {
    //   setShowPopup(false);
    //   window.location.href = '/';
    // }, 3000);
    
    // You can update state or perform other actions with the API response
  } catch (error) {
    console.error('Error calling API:', error);
  }
};

const onChange = (recaptchaResponse) => {
  // Verify the reCAPTCHA response
  setInputValue(prevState => ({
    ...prevState,
    contact_us_response: recaptchaResponse
  }));
 
}



  const isSubmitDisabled = !inputValue.name || !inputValue.client_users_phone || !inputValue.email || !inputValue.company;
  // const recaptchaRef = React.createRef();

  return (
    <>
      <div className="header-nav">
        <div className="header-container">
          <div className="header-section">
            <div className="logo">
              <img
                className="media-logo"
                src="images/img_epriselogo.png"
                alt="eprise-logo"
              />
            </div>
            <ul>
              <li><Link to="/">Home</Link></li>
            </ul>
          </div>
          {/* <div className="header-section">
            <button className="btn header-btn">Request a Demo</button>
          </div> */}
        </div>
      </div>

      <div className="demo-page">
        <div className="demo-container">
          <div className="demo-section">
            <div className="demo-section-content">
              <img
                className="DemoPage-logo"
                src="images/img_epriselogo2.png"
                alt="Eprise Logo"
              />

              <h2> Schedule your Demo</h2>

              <p>
                Experience seamless financial management with our intuitive
                accounting website. Streamline your bookkeeping, track expenses,
                and generate insightful reports effortlessly. Request a demo
                today and take control of your finances with ease.
              </p>

              <div className="demo-contacts">
                <div className="demo-contancts-row">
                  <img
                    className="loc-icon"
                    src="images/e1.png"
                    alt="linledln"
                  />
                  <p>support@qliclabs.com</p>
                </div>
                <div className="demo-contancts-row">
                  <img
                    className="loc-icon"
                    src="images/c1.png"
                    alt="linledln"
                  />
                  <p>+91 8904052211</p>
                </div>
                <div className="footer-logo">
                  <a
                    href="https://www.youtube.com/@qliclabs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Demo-Page-img"
                      src="images/Youtube.png"
                      alt="youtube"
                    />
                  </a>

                  <a
                    href="https://www.instagram.com/qliclabs/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Demo-Page-img"
                      src="images/Instagram1.png"
                      alt="instagram"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/31312745/admin/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Demo-Page-img"
                      src="images/in1.png"
                      alt="linledln"
                    />
                  </a>

                  <a
                    href="https://twitter.com/qliclabs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Demo-Page-img"
                      src="images/Twitter1.png"
                      alt="twitter"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="demo-section">
            <div className="demo-form">
              <form onSubmit={handleSubmit}>
                <input
                  type="text" value={inputValue.name} onChange={handleInputChange}
                  class="form-control"
                  id="name" name="name"
                  placeholder="Name" 
                  required
                />
                <input
                  type="number" value={inputValue.client_users_phone} onChange={handleInputChange}
                  class="form-control"
                  id="client_users_phone"
                  name="client_users_phone"
                  placeholder="Phone"
                  required
                />

            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Enter Email"
              name="email"           
              value={inputValue.email}
              onChange={handleInputChange}
              required
            />


                <input
                  type="text" value={inputValue.company} onChange={handleInputChange}
                  class="form-control"
                  id="company"
                  name="company"
                  placeholder="Company Name"
                  required
                />
                <textarea  
                  type="text" value={inputValue.comments} onChange={handleInputChange}
                  class="form-control"
                  rows="5"
                  id="comments"
                  name="comments"
                  placeholder="Comments"
                />

                  <ReCAPTCHA dataSize="compact" className="compact"
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                  />
                <br/>

                <button className="btn demo-btn" type="submit" disabled={isSubmitDisabled}>
                  Submit
                </button>
              </form>
              <Popup open={showPopup} onClose={() => setShowPopup(false)}>
                <div>{message && message.message}</div>
              </Popup>
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoPage;

// <div className="Demo-Page-Container">
//   <div className="header-nav">
//     <div className="header-container">
//       <div className="header-section">
//         <div className="logo">
//           <img
//             className="media-logo"
//             src="images/img_epriselogo.png"
//             alt="eprise-logo"
//           />
//         </div>
//         <ul>
//           <li>Home</li>
//         </ul>
//       </div>
//       <div className="header-section">
//         <button className="btn header-btn">Request a Demo</button>
//       </div>
//     </div>
//   </div>
//   <div className="Demo-Page-Container-row">
//     <div className="div-col-6">
//       <div className="demo-page-section">
//         <img
//           className="DemoPage-logo"
//           src="images/img_epriselogo2.png"
//           alt="Eprise Logo"
//         />
//         <h2> Schedule your Demo</h2>
//         <p>
//           Experience seamless financial management with our intuitive
//           accounting website. Streamline your bookkeeping, track expenses,
//           and generate insightful reports effortlessly. Request a demo today
//           and take control of your finances with ease.
//         </p>
//         <div className="Demo-Page-PhoneNo">
//           <img className="loc-icon" src="images/call.png" alt="linledln" />
//           <p>support@qliclabs.com</p>
//         </div>
//         <div className="Demo-Page-PhoneNo">
//           <img className="loc-icon" src="images/call.png" alt="linledln" />
//        
//           <p>+91 8904052211</p>
//         </div>
//       </div>
//       <div className="Demo-Page-footer-logos">
//         <a
//           href="https://www.youtube.com/@qliclabs"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <img
//             className="Demo-Page-img"
//             src="images/Youtube.png"
//             alt="youtube"
//           />
//         </a>

//         <a
//           href="https://www.instagram.com/qliclabs/"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <img
//             className="Demo-Page-img"
//             src="images/Instagram1.png"
//             alt="instagram"
//           />
//         </a>
//         <a
//           href="https://www.linkedin.com/company/31312745/admin/"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <img
//             className="Demo-Page-img"
//             src="images/in1.png"
//             alt="linledln"
//           />
//         </a>

//         <a
//           href="https://twitter.com/qliclabs"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <img
//             className="Demo-Page-img"
//             src="images/Twitter1.png"
//             alt="twitter"
//           />
//         </a>
//       </div>
//     </div>
//     <div className="div-col-6">
//       <div className="Demo-Page-Form-Container">
//         <form onSubmit={handleSubmit}>
//           <div className="form-spacer">
//             <label for="name" className="form-label">
//               Name:
//             </label>
//             <input
//               type="text"
//               className="form-control increased-width"
//               id="name"
//               placeholder="Enter Name"
//               name="name"
//               value={formData.name}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//           <div className="form-spacer">
//             <label for="phone" className="form-label">
//               Phone:
//             </label>
//             <input
//               type="number"
//               className="form-control increased-width"
//               id="phone"
//               placeholder="Enter Phone"
//               name="phone"
//               value={formData.phone}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//           <div className="form-spacer">
//             <label for="email" className="form-label">
//               Email:
//             </label>
//             <input
//               type="email"
//               className="form-control increased-width"
//               id="email"
//               placeholder="Enter Email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//           <div className="form-spacer">
//             <label for="companyname" className="form-label">
//               Company Name:
//             </label>
//             <input
//               type="text"
//               className="form-control increased-width"
//               id="companyname"
//               placeholder="Enter Company Name"
//               name="companyname"
//               value={formData.companyname}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//           <div className="form-spacer">
//             <label for="name" className="form-label">
//               Comments:
//             </label>
//             <textarea
//               type="text"
//               className="form-control increased-width"
//               id="comments"
//               placeholder="Enter Comments"
//               name="comments"
//               rows="4"
//               value={formData.comments}
//               onChange={handleInputChange}
//               required
//             />

//             <ReCAPTCHA  className="form-spacer recaptcha"
//               sitekey="6LcaPT0jAAAAADul4l0bPps41z4YrXM8uoPsqhFP"
//               onChange={onChange}
//             />

//             <button className="btn form-spacer increased-width" type="submit">
//               Request a Demo
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   </div>
// </div>

// const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
// const [formData, setFormData] = useState({
//   name: '',
//   phone: '',
//   email: '',
//   companyname: '',
//   comments: '',
// });

// const handleRecaptchaChange = (value) => {
//   if (value) {
//     setRecaptchaVerified(true);
//   } else {
//     setRecaptchaVerified(false);
//   }
// };

// const handleSubmit = (e) => {
//   e.preventDefault();

//   if (isRecaptchaVerified) {
//     
//     // Your form submission logic here
//   } else {
//     console.error('reCAPTCHA verification failed.');
//     // Display error or prevent form submission
//   }
// };

