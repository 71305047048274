import React from "react";
import "../core-ui/MainFooter.css";
import { Link } from "react-router-dom";

const MainFooter = () => {
  return (
    <>
      <div className="main-footer-container">
        <div className="main-footer-section-1">
          <div>
            <div className="logo">
              <img
                className="media-logo"
                src="images/img_epriselogo.png"
                alt="eprise-logo"
              />
            </div>
          </div>
          <div>
            <h6>Company</h6>
            <Link to="/privacy-policy">
            <p>Privacy Policy</p>
            </Link>
            <Link to="/terms-and-conditions">
            <p>Terms & Conditions </p>
            </Link>
            <Link to="/refund-and-cancellation">
            <p>Refund and Cancellation Policy </p>
            </Link>
          </div>
          <div>
            <div className="section-1-grid-items">
              <img className="loc-icon" src="images/l1.png" alt="location" />
              <p>
                IKP, K-Tech Innovation Hub, 3rd Floor, Plama Center, Bejai
                Kapikad Road, Mangaluru, Karnataka - 575004.
              </p>
            </div>
            <div className="section-1-grid-items">
              <img
                className="loc-icon phone-icon"
                src="images/c1.png"
                alt="phone"
              />
              <h6>
                <a href="tel:+918904052211">+918904052211</a>
              </h6>
            </div>

            {/* <div className="section-1-grid-items main-footer-icons">
              <a
                href="https://www.youtube.com/@qliclabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/Youtube.png"
                  alt="youtube"
                  class="img-fluid"
                />
              </a>
              <a
                href="https://www.instagram.com/qliclabs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/Instagram1.png"
                  alt="instagram"
                  class="img-fluid"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/31312745/admin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/in1.png"
                  alt="linledln"
                  class="img-fluid"
                />
              </a>

              <a
                href="https://twitter.com/qliclabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/Twitter1.png"
                  alt="twitter"
                  class="img-fluid"
                />
              </a>
            </div> */}
            <h6>Question or feedback? We'd love to hear from you!</h6>
            <a href="mailto:support@qliclabs.com">
              <h6 className="email-link">support@qliclabs.com</h6>
            </a>
          </div>
        </div>
        <div className="main-footer-section-1">
          <div></div>
          <div>
            {" "}
            <p>© Copyright Qliclabs Technologies Pvt. Ltd. 2024</p>
          </div>
          <div>
            <div className="section-1-grid-items main-footer-icons">
              <a
                href="https://www.youtube.com/@qliclabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/Youtube.png"
                  alt="youtube"
                  class="img-fluid"
                />
              </a>
              <a
                href="https://www.instagram.com/qliclabs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/Instagram1.png"
                  alt="instagram"
                  class="img-fluid"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/31312745/admin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/in1.png"
                  alt="linledln"
                  class="img-fluid"
                />
              </a>

              <a
                href="https://twitter.com/qliclabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/Twitter1.png"
                  alt="twitter"
                  class="img-fluid"
                />
              </a>
			  <a
                href="https://www.threads.net/@qliclabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="footerimg"
                  src="images/threads.png"
                  alt="threads"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainFooter;
