import React, { useEffect } from "react";
import Header from "../components/Header";
//import Footer from "../components/Footer";
import MainFooter from "../components/MainFooter";
import "../core-ui/Refund.css";
import { Link } from "react-router-dom";

const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="refund-policy-container">
        <div></div>
        <div className="refund-policy-content">
          <h1>Cancellation and Refund Policy </h1>
          <div className="horizontal-line"></div>
          <p>Last updated on: 01st September 2024</p>
          <p>
            This Cancellation and Refund Policy shall apply to any cancellation
            of subscription of the Eprise Products and applicable refunds.
            Qliclabs shall not entertain any refund requests if cancellation of
            subscription is in violation of this policy or its Terms of Service
            or if Qliclabs terminates the User account for violation of its
            Terms of Service.{" "}
          </p>
          <p>
            This Cancellation and Refund Policy is part of and incorporated
            within and is to be read along with the{" "}
            <Link to="/terms-and-conditions">
              <span>Terms of Service</span>
            </Link>{" "}
            [URL of Terms of Services]. The terms defined herein shall have the
            meaning assigned to them in the Terms of Service.
          </p>
          <ol type="1">
            <li>
              Qliclabs permits cancellation of subscription if a User is not
              satisfied with the Eprise product or service. Cancellation of a
              first-time subscription is eligible for refund only within 7 days
              from the date of purchase of subscription (“Purchase Date”).
              Cancellation of subscription is not eligible for any refund.{" "}
            </li>
            <li>
              A User can only avail refund once. Renewal of subscription after
              the expiration of its term shall not entitle a User to avail
              refund.{" "}
            </li>
            <li>
              The date on invoice for subscription shall be considered as final
              and conclusive for determining the Purchase Date.{" "}
            </li>
            <li>
              Qliclabs may permit Users to upgrade their subscription by
              purchasing a higher priced subscription. Where a User upgrades its
              subscription, the refund shall be governed by the Purchase Date
              and not from the date of upgrade of subscription (“Upgrade Date”).
              To clarify, a User who avails an upgrade is eligible for refund
              upon cancellation of the subscription within 7 days from the
              Purchase Date only.{" "}
            </li>
            <li>
              Under no circumstance shall a User be entitled to a refund upon
              cancellation of subscription after 7 days from the Purchase Date.{" "}
            </li>
            <li>
              All requests for cancellation of subscription and refund should be
              raised only via the customer support helpline provided by
              Qliclabs. Cancellation and refund requests raised through any
              other mode of communication will not be considered for refund
            </li>
            <li>
              Qliclabs has arrangements with banks, affiliates, payment
              gateways, payment aggregators and other financial service
              providers for processing refunds (“Service Providers”). Qliclabs
              endeavours to ensure that eligible refunds are processed within 20
              working days of your request for cancellation of subscription.
              However, this timeline is indicative and processing of refunds is
              subject to the time taken by the Service Providers.
            </li>
            <li>
              The User agrees to provide any information required to facilitate
              refunds including but not limited to bank account details, bank
              branch names, UPI addresses, IFSC codes.{" "}
            </li>
            <li>
              The User acknowledges and agrees that remittance of refund to the
              User is subject to the charges that may be levied by the Service
              Providers for such remittance. Such charges shall be deducted from
              the refund claimed by the User.{" "}
            </li>
            <li>
              Notwithstanding anything contained in this policy, this
              Cancellation and Refund Policy shall not apply to the Users, who
              register and purchase subscription of Qliclabs Products (A) using
              the referral code/referral link of existing Qliclabs' Users; or
              (B) by availing no-cost EMI payment solutions by third parties.
              Accordingly, such Users shall not be eligible for any refund from
              Qliclabs.{" "}
            </li>
          </ol>
        </div>
        <div></div>
      </div>
      <MainFooter />
    </>
  );
};

export default Refund;
