import React, { Component } from 'react'
import Home from './Pages/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DemoPage from './Pages/DemoPage'
//import Aboutus from './Pages/Aboutus';
import Privacy from './Pages/Privacy';
import Refund from './Pages/Refund';
import Terms from './Pages/Terms';

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <div>
        <main> 
        <Routes>
          <Route exact path="/" element={<Home/>}/> 
          <Route path="/demo" element={<DemoPage/>}/>
          {/* <Route path="/about" element={<Aboutus/>}/>  */}
          <Route path="/privacy-policy" element={<Privacy/>}/>
          <Route path="/refund-and-cancellation" element={<Refund/>}/>
          <Route path="/terms-and-conditions" element={<Terms/>}/>    
        </Routes>
        </main>
      </div>
        {/* <Home/> */}
      </BrowserRouter>
    )
  }
}
